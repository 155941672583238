<template>
  <v-row v-if="Lb_isLoaded">
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-title>{{$t('general.experience_marks')}}</v-card-title>
        <v-card-text>
          <v-row
            align="center"
            class="ml-2"
          >
            <v-col
              v-if="a_Subjects"
              class="d-flex"
              cols="11"
              sm="5"
            >
              <v-select
                item-value="i_ClassSubjectID"
                item-text="s_Name"
                :items="a_Subjects"
                :label="$t('general.select_your_subject')"
                dense
                @change="getSubjectExams"
              />
            </v-col>
          </v-row>
          <v-card-subtitle v-if="s_Teacher">
            {{$t('general.teacher')}} : {{ s_Teacher }}
          </v-card-subtitle>
          <v-simple-table v-if="a_Exams">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{$t('general.date')}}
                  </th>
                  <th class="text-left">
                    {{$t('general.designation')}}
                  </th>
                  <th class="text-left">
                    {{$t('general.weighting')}}
                  </th>
                  <th class="text-left">
                    {{$t('general.mark')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="exam in a_Exams"
                  :key="exam.s_Name"
                >
                  <td>{{ $smt.getSwissDate(exam.t_Date) }}</td>
                  <td>{{ exam.s_Name }}</td>
                  <td>{{ exam.f_Factor }}</td>
                  <td>{{ exam.f_Value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import store from '@/store'
  import { get } from '../../../worker/worker-api'
  import { mapGetters } from 'vuex'
  export default {
    metaInfo() {
      return{      
        // title will be injected into parent titleTemplate
        title: this.$t('general.experience_marks'),
      }
    },
    data () {
      return {
        a_TermMarkDate: [],
        a_Subjects: [],
        s_Teacher: '',
        a_Exams: [],
        Lb_isLoaded: false,
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID']),
    },
    mounted () {
      this.getStudentTermMarkDates(this.$smt.getAddressRoleID(this.$route.meta.role), store.getters.getPlanningPeriodID)
    },
    methods: {
      getSubjectExams (Ai_ClassSubjectID) {
        const La_SelectedSubject = this.a_Subjects.filter(o_Subject => o_Subject.i_ClassSubjectID === Ai_ClassSubjectID);
        if(La_SelectedSubject.length > 0) this.s_Teacher = La_SelectedSubject[0].s_Teacher;
        else this.s_Teacher = null;

        const a_SubjectsExams = this.a_TermMarkDate.filter(itemInArray => itemInArray.i_ClassSubjectID === Ai_ClassSubjectID)
        if(a_SubjectsExams.length > 0) {
          this.a_Exams = a_SubjectsExams[0].exams;
        } else {
          this.a_Exams = [];
        }
      },
      getStudentTermMarkDates (Ai_SSP_AddressRoleID, Ai_PlanningPeriodID) {
        store.dispatch('changeThemeLoadingState', true)
        this.Lb_isLoaded = false
        return get(`${process.env.VUE_APP_SMT_API_URL}/student/${Ai_SSP_AddressRoleID}/experience-marks/planning-period/${Ai_PlanningPeriodID}`, {})
          .then(response => {
            this.a_Subjects = response.ClassSubject;
            this.a_TermMarkDate = this.formatTermMarkDate(response.TermMark);
            this.Lb_isLoaded = true
            setTimeout(() => store.dispatch('changeThemeLoadingState', false), 700)
          })
          .catch(error => {
            this.Lb_isLoaded = true
            store.dispatch('changeThemeLoadingState', false)
          })
      },
      formatTermMarkDate(Aa_TermMark) {
        let La_Marks = [];
        const La_TermMarkGroups = Aa_TermMark.reduce((groups, item) => ({
          ...groups,
          [item.i_ClassSubjectID]: [...(groups[item.i_ClassSubjectID] || []), item]
        }), {});

        Object.entries(La_TermMarkGroups).forEach(a_TermMarkGroup => {
          La_Marks.push({
            i_ClassSubjectID: parseInt(a_TermMarkGroup[0]),
            exams: a_TermMarkGroup[1]
          });
        });

        return La_Marks;
      },
    },
  }
</script>
